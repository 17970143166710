<template>
  <s-drawer
    type="full"
    :visible.sync="show"
    direction="rtl"
    append-to-body
    no-header
    immediately-render
  >
    <div class="j-map-address j-address-box c-google-map-modal">
      <div style="height: 100%">
        <s-title-nav
          ref="upperHeader"
          class="c-address-header"
          :title="language.SHEIN_KEY_PWA_16611"
        >
          <template slot="preblock">
            <s-title-nav-item
              ref="backBtn"
              icon-class="suiiconfont sui_icon_nav_back_24px"
              @click.native="clickCancel"
            />
          </template>
        </s-title-nav>
        <div
          ref="resultList"
          class="c-address-content c-map-wrap"
          :class="{ 'page-empty': emptyFlag }"
        >
          <div class="left address">
            <div class="search__top">
              <template v-if="isGoogleSearch">
                <div
                  v-if="!mapFlag"
                  class="select-map"
                >
                  <i class="suiiconfont sui_icon_location_15px"></i>
                  <span
                    v-enterkey
                    class="value"
                    DA-type="syncClick"
                    DA-sa-name="pickupaddress_selectfrommap"
                    tabindex="0"
                    role="button"
                    @click="clickSelectMap"
                  >{{ language.SHEIN_KEY_PWA_16614 }} ></span>
                </div>
                <search-list
                  ref="searchListRef"
                  :country-id="countryId"
                  :language="language"
                  :map="mapFlag"
                  @select="clickSearch"
                  @focus="handleSearchlistFocus"
                />
              </template>
              <template v-else>
                <div
                  class="header-search"
                  :class="{ focus: searchFocus }"
                >
                  <div
                    class="search-input"
                    @click="searchInputFocus"
                  >
                    <i class="iconfont icon-search01"></i>
                    <input
                      ref="searchInput"
                      v-model="searchValue"
                      type="text"
                      @focus="searchInputFocus"
                      @blur="searchInputBlur"
                    />
                    <p
                      v-if="!searchValue"
                      class="text-placeholer"
                      tabindex="0"
                      v-html="language.SHEIN_KEY_PWA_16612"
                    ></p>
                    <i
                      v-if="searchFocus && searchValue"
                      v-enterkey
                      class="iconfont icon-clear1"
                      tabindex="0"
                      role="button"
                      :aria-label="language.SHEIN_KEY_PWA_16134"
                      @click="clickSearchClose"
                    ></i>
                  </div>
                  <div class="search-right">
                    <span
                      v-enterkey
                      role="button"
                      DA-type="syncClick"
                      DA-sa-name="pickupaddress_search"
                      tabindex="0"
                      @click="clickSearch"
                    >{{ language.SHEIN_KEY_PWA_15173 }}</span>
                  </div>
                </div>
                <div
                  v-if="errorFlag"
                  class="search-error"
                  tabindex="0"
                >
                  <p>{{ postcodeTips }}</p>
                </div>
                <div
                  v-if="!mapFlag"
                  class="select-map"
                >
                  <i class="suiiconfont sui_icon_location_15px"></i>
                  <span
                    v-enterkey
                    class="value"
                    DA-type="syncClick"
                    DA-sa-name="pickupaddress_selectfrommap"
                    tabindex="0"
                    role="button"
                    @click="clickSelectMap"
                  >{{ language.SHEIN_KEY_PWA_16614 }} ></span>
                </div>
              </template>
              <div
                v-if="isShowLogicts"
                class="filter"
              >
                <div
                  class="filter__category"
                  @click="toggleShowLogictsCategory"
                >
                  <span class="text">{{ language.SHEIN_KEY_PWA_22959 }}:
                    {{ logictsCategory.label || language.SHEIN_KEY_PWA_22961 }}</span>
                  <i
                    class="suiiconfont"
                    :class="
                      logictsCategory.isShow ? 'sui_icon_more_up_16px' : 'sui_icon_more_down_16px'
                    "
                  >
                  </i>
                </div>
                <div class="filter__select">
                  <ul
                    v-show="logictsCategory.isShow"
                    class="filter__list"
                  >
                    <li
                      v-for="item in categoryLists"
                      :key="item.id"
                      class="item"
                      :class="{ selected: logictsCategory.id === item.id }"
                      @click="selectCategory(item)"
                    >
                      <span class="label">{{ item.label }}</span>
                      <i
                        v-if="logictsCategory.id === item.id"
                        class="suiiconfont sui_icon_selected_16px"
                      >
                      </i>
                    </li>
                  </ul>
                  <transition name="S-animation-modal__drawer">
                    <div
                      v-show="logictsCategory.isShow"
                      class="modal"
                      @click="toggleShowLogictsCategory"
                    ></div>
                  </transition>
                </div>
              </div>
            </div>
            <div
              v-show="!mapFlag && resultList.length"
              class="search-result"
              :style="{ marginTop: resultMarginTop }"
            >
              <ul class="result-list">
                <li
                  v-for="(item, index) in resultList"
                  :key="index + item.storeId"
                  :ref="'j-item-' + index"
                  v-enterkey
                  class="result-item"
                  tabindex="0"
                  role="button"
                  :aria-selected="selectedIndex === index"
                  :class="{ selected: selectedIndex === index }"
                  @click="selectItem(index)"
                >
                  <s-radio
                    :value="parseInt(selectedIndex)"
                    margin-r="0"
                    :label="index"
                  />
                  <div class="result-content">
                    <p class="title flex">
                      <span class="name">{{ item.storeName }}</span>
                      <span
                        v-if="item.distance"
                        class="distance"
                      >{{ transformDistance(item) }}</span>
                    </p>
                    <div
                      v-if="
                        getLocationFields({ info: item, type: 'logo' }) ||
                          getLocationFields({ info: item, type: 'label' }) ||
                          item.isRecommend
                      "
                      class="logistics"
                    >
                      <div
                        v-if="getLocationFields({ info: item, type: 'logo' })"
                        class="logo-panel"
                      >
                        <img
                          class="logo"
                          :src="getLocationFields({ info: item, type: 'logo' })"
                          alt="logo"
                        />
                      </div>
                      <span
                        v-if="getLocationFields({ info: item, type: 'label' })"
                        class="type"
                      >
                        {{
                          getLocationFields({ info: item, type: 'label' })
                        }}
                      </span>
                      <div
                        v-if="item.isRecommend"
                        class="recommend"
                      >
                        <i class="suiiconfont sui_icon_time_15px"></i>
                        <span class="txt">{{ language.SHEIN_KEY_PWA_24653 }}</span>
                      </div>
                    </div>
                    <div class="desc flex">
                      <i class="suiiconfont sui_icon_location_15px"></i>
                      <div class="info inline">
                        <span>{{ item.address }}</span>
                        <a
                          v-if="isShowPicture && item.image"
                          class="link"
                          href="javascript:;"
                          @click.stop="toShowPicture(item.image)"
                        >
                          {{ language.SHEIN_KEY_PWA_20625 }} &gt;
                        </a>
                      </div>
                    </div>
                    <p
                      v-if="showTimesIcon(item)"
                      class="open-time flex"
                      @click.stop="clickChildStatus(item)"
                    >
                      <i class="suiiconfont sui_icon_time_15px"></i>
                      <span
                        v-if="item.store247 === '1'"
                        class="info"
                      > {{ language.SHEIN_KEY_PWA_20626 }}</span>
                      <span
                        v-else-if="item.open_close_time"
                        class="info"
                      > {{ item.open_close_time }}</span>
                      <span
                        v-else
                        class="info"
                      >
                        {{ language.SHEIN_KEY_PWA_16633 }}
                        <i
                          class="iconfont"
                          :class="item.childShow ? 'icon-shang' : 'icon-down'"
                        ></i>
                      </span>
                    </p>
                    <div
                      v-show="item.childShow && item.store247 !== '1' && !item.open_close_time"
                      class="child"
                    >
                      <time-vue
                        :time-info="item"
                        :language="language"
                      />
                    </div>
                    <post-number
                      v-if="item.storeType === '19' && selectedIndex === index"
                      :language="language"
                      :store-register-code="storeRegisterCode"
                      @edit="toggleShowPostNumber({ type: true, from: 'edit' })"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="emptyFlag"
            class="page-empty"
            tabindex="0"
            :style="{ marginTop: resultMarginTop }"
          >
            <img
              :src="PUBLIC_CDN + '/pwa_dist/images/morecolor/empty-search-918121213e.png'"
              alt=""
            />
            <p v-html="language.SHEIN_KEY_PWA_16615"></p>
          </div>
          <div
            v-show="mapFlag"
            id="j-store-map"
            class="map"
            :class="{ error: errorFlag }"
          ></div>
          <!-- 选择 滑出层-->
          <LazyMount>
            <s-drawer
              :visible.sync="chooseAddressDrawer"
              :modal="false"
              append-to-body
              style="pointer-events: none"
              class="choose-address-page"
            >
              <div
                class="choose-address-wrap"
                style="pointer-events: auto"
              >
                <div
                  ref="closeBtn"
                  v-enterkey
                  class="header-close"
                  tabindex="0"
                  role="button"
                  :aria-label="language.SHEIN_KEY_PWA_15567"
                  @click="clickDrawerClose"
                >
                  <i class="iconfont icon-close"></i>
                </div>
                <div class="content">
                  <h5 tabindex="0">
                    {{ choseeAddressInfo.storeName }}
                  </h5>
                  <div
                    v-if="
                      getLocationFields({ info: choseeAddressInfo, type: 'logo' }) ||
                        getLocationFields({ info: choseeAddressInfo, type: 'label' })
                    "
                    class="logistics"
                  >
                    <div
                      v-if="getLocationFields({ info: choseeAddressInfo, type: 'logo' })"
                      class="logo-panel"
                    >
                      <img
                        class="logo"
                        :src="getLocationFields({ info: choseeAddressInfo, type: 'logo' })"
                        alt="logo"
                      />
                    </div>
                    <span
                      v-if="getLocationFields({ info: choseeAddressInfo, type: 'label' })"
                      class="type"
                    >
                      {{
                        getLocationFields({ info: choseeAddressInfo, type: 'label' })
                      }}
                    </span>
                  </div>
                  <div
                    class="desc flex"
                    tabindex="0"
                  >
                    <i class="suiiconfont sui_icon_location_15px"></i>
                    <div class="info inline">
                      <span>{{ choseeAddressInfo.address }}</span>
                      <a
                        v-if="isShowPicture && choseeAddressInfo.image"
                        class="link"
                        href="javascript:;"
                        @click.stop="toShowPicture(choseeAddressInfo.image)"
                      >
                        {{ language.SHEIN_KEY_PWA_20625 }} &gt;
                      </a>
                    </div>
                  </div>
                  <div
                    v-if="showTimesIcon(choseeAddressInfo)"
                    class="open-time flex"
                    tabindex="0"
                  >
                    <i class="suiiconfont sui_icon_time_15px"></i>
                    <div
                      v-if="choseeAddressInfo.store247 === '1'"
                      class="info"
                    >
                      {{ language.SHEIN_KEY_PWA_20626 }}
                    </div>
                    <div
                      v-else-if="choseeAddressInfo.open_close_time"
                      class="info"
                    >
                      {{ choseeAddressInfo.open_close_time }}
                    </div>
                    <div
                      v-else
                      class="info child"
                    >
                      <time-vue
                        :time-info="choseeAddressInfo"
                        :language="language"
                      />
                    </div>
                  </div>
                  <post-number
                    v-if="choseeAddressInfo.storeType === '19'"
                    :language="language"
                    :store-register-code="storeRegisterCode"
                    @edit="toggleShowPostNumber({ type: true, from: 'edit' })"
                  />
                </div>
                <div class="bottom">
                  <button
                    class="mshe-btn-black mshe-btn-block j-adr-submit-btn"
                    DA-type="syncClick"
                    :disabled="!canSelectItem"
                    @click="clickSelected"
                  >
                    {{ language.SHEIN_KEY_PWA_15150 }}
                  </button>
                </div>
              </div>
            </s-drawer>
          </LazyMount>
        </div>
        <div
          v-if="!mapFlag && resultList.length"
          class="result-bottom"
        >
          <button
            v-if="!canSelectItem"
            class="mshe-btn-black mshe-btn-block j-adr-submit-btn"
            disabled
          >
            {{ language.SHEIN_KEY_PWA_15150 }}
          </button>
          <button
            v-else
            class="mshe-btn-black mshe-btn-block j-adr-submit-btn"
            DA-type="syncClick"
            DA-sa-name="pickupaddress_confirm"
            @click="clickSelected"
          >
            {{ language.SHEIN_KEY_PWA_15150 }}
          </button>
        </div>
      </div>
      <LazyMount>
        <s-image-box
          class="store-picture"
          :visible.sync="storePicture.isShow"
          append-to-body
        >
          <i
            class="iconfont icon-close close"
            @click.stop="storePicture.isShow = false"
          ></i>
          <img
            class="img lazyload"
            :data-src="storePicture.link"
            alt="store picture"
          />
        </s-image-box>
      </LazyMount>
      <LazyMount>
        <s-drawer
          :visible.sync="postNumber.isShow"
          append-to-body
          class="store-post-number"
        >
          <template slot="top">
            {{ language.SHEIN_KEY_PWA_22966 }}
          </template>
          <div class="content">
            <div
              class="desc"
              v-html="language.SHEIN_KEY_PWA_22964"
            ></div>
            <input
              v-model="postNumber.code"
              class="input"
              :placeholder="language.SHEIN_KEY_PWA_22965"
            />
            <div
              v-if="error.store_type_code.show"
              class="error"
            >
              {{ error.store_type_code.value }}
            </div>
            <div class="btn">
              <s-button
                width="100%"
                :type="['primary', 'H44PX']"
                :disabled="!postNumber.code"
                @click="confirmPostNumber"
              >
                {{ language.SHEIN_KEY_PWA_14902 }}
              </s-button>
            </div>
          </div>
        </s-drawer>
      </LazyMount>
    </div>
  </s-drawer>
</template>
<script>
import { saObj } from '../analysis'
import timeVue from './time'
import {
  MAP_MARKER_ICONS,
  countryConfigs,
  RU_STORE_LOCATION_TYPE,
  RU_STORE_LAST_LOGISTICS,
  GOOGLE_DATA
} from '../config'
import searchList from './search_list_store'
import {
  ImageBox as SImageBox,
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem,
  Radio as SRadio,
  Drawer as SDrawer
} from '@shein/sui-mobile'
import { asyncLoadFile, isArray, isNumber } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import postNumber from './post_number.vue'
import { abtservice } from 'public/src/services/abt'
import { LazyMount } from '@shein/sui-mobile'

const { icon1, icon2, icon3 } = MAP_MARKER_ICONS
const {
  PUBLIC_CDN,
  SHOP_TRANSIT_GEOCODE_COUNTRIES,
  GOOGLE_API_KEY,
  GOOGLE_LAT_LNG_ACCURACY,
} = gbCommonInfo

export default {
  name: 'MapVue',
  components: {
    timeVue,
    searchList,
    SImageBox,
    postNumber,
    STitleNav,
    STitleNavItem,
    SRadio,
    SDrawer,
    LazyMount
  },
  props: {
    abtTestConfig: {
      type: Object,
      default() {
        return {
          google: 'off',
          distance: 'off',
          pic: 'off',
          ShowType: 'off'
        }
      }
    },
    addressStoreConfig: {
      type: Object,
      default() {
        return {
          store_config: {
            distance: 0,
            sort_type: 0
          },
          abtTestConfig: {
            search_home: 'off',
            google: 'off',
            ShowLogo: 'off',
            distance: 'off',
            pic: 'off',
            ShowType: 'off',
            logicts: 'off',
            channel: [],
          },
          store_info_list: []
        }
      }
    }
  },
  data() {
    return {
      language: {},
      storeId: '',
      pageName: '',
      countryId: '',
      show: false,
      PUBLIC_CDN,
      mapObj: '',
      selectedIndex: '',
      selectedMarker: '',
      googleMapShow: false,
      searchValue: '',
      searchFocus: false,
      errorFlag: false,
      resultList: [],
      originResultList: [],
      mapFlag: false,
      emptyFlag: false,
      chooseAddressDrawer: false,
      choseeAddressInfo: {},
      markerList: [],
      myMarker: '',
      AddressCheckRule: null,
      error: {
        postcode: {
          value: '',
          show: false,
          flag: false
        },
        store_type_code: {
          value: '',
          show: false
        }
      },
      storePicture: {
        isShow: false,
        link: ''
      },
      state: '',
      city: '',
      extraData: {},
      storeRegisterCode: '',
      postNumber: {
        isShow: false,
        code: ''
      },
      logictsCategory: {
        isShow: false,
        id: '0',
        label: ''
      },
      sortType: '',
      transportType: ''
    }
  },
  computed: {
    canSelectItem() {
      if (this.selectedIndex === '') {
        return false
      }
      const { storeType } = this.resultList?.[this.selectedIndex] || {}
      return storeType === '19' ? !!this.storeRegisterCode : true
    },
    isFr() {
      return this.countryId == 74
    },
    isFrOrDe() {
      return this.countryId == 74 || this.countryId == 82
    },
    isShowPicture() {
      return this.abtTestConfig.pic === 'on'
    },
    isShowType() {
      return this.abtTestConfig.ShowType === 'on'
    },
    isShowLogo() {
      return this.abtTestConfig.ShowLogo === 'on'
    },
    postcodeTips() {
      const { SHEIN_KEY_PWA_15866, SHEIN_KEY_PWA_15945, SHEIN_KEY_PWA_15899, SHEIN_KEY_PWA_15989 } =
        this.language
      const tips = {
        74: SHEIN_KEY_PWA_15866,
        82: SHEIN_KEY_PWA_15866,
        225: SHEIN_KEY_PWA_15945,
        13: SHEIN_KEY_PWA_15899,
        198: SHEIN_KEY_PWA_15989
      }
      return this.error.postcode.value || tips[this.countryId]
    },
    channels() {
      try {
        const channel = this.abtTestConfig.channel
        if (!channel) {
          return []
        }
        return channel
      } catch (error) {
        return []
      }
    },
    isSupportGoogleSearch() {
      return Reflect.ownKeys(GOOGLE_DATA).includes(String(this.countryId))
    },
    isGoogleSearch() {
      return this.abtTestConfig.google === 'on' && this.isSupportGoogleSearch
    },
    isSearchHome() {
      return this.abtTestConfig.search_home === 'on'
    },
    isShowLogicts() {
      return this.countryId == 82 && this.abtTestConfig.logicts === 'on'
    },
    categoryLists() {
      const lists = [
        {
          id: '0',
          label: this.language.SHEIN_KEY_PWA_22961
        }
      ]
      if(this.addressStoreConfig?.store_info_list?.length){
        this.addressStoreConfig?.store_info_list.forEach(item => {
          lists.push({
            id: item.store_channel_type,
            label: item.store_channel_name_multi
          })
        })
      }
      return lists
    },
    resultMarginTop() {
      return this.isShowLogicts ? '3.58rem' : '2.3rem'
    }
  },
  watch: {
    'logictsCategory.id'(n) {
      this.selectedIndex = ''
      if (!Number(n)) {
        this.resultList = this.originResultList
        this.setMapMarker()
        return
      }
      const list = this.originResultList.filter(item => item.storeType === n)
      this.resultList = list
      this.setMapMarker()
    }
  },
  async created() {
    this.getSortTypeAbt()
    this.searchCache = {}
    this.language = await this.fetchData()
  },
  methods: {
    showTimesIcon(item){
      return (item.timeLists && item.timeLists.length && item.timeLists.some(data => data.time !== '-')) || item.store247 === '1' || item.open_close_time
    },
    async getSortTypeAbt() {
      if (isNumber(this.sortType)) {
        return
      }
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: 'StoreSortMethod' })
      const sortType =
        abtInfo?.['StoreSortMethod']?.param?.['Store_Sort_Method'] === 'cost_sort' ? 1 : 0
      this.sortType = sortType
    },
    selectCategory({ id, label }) {
      this.logictsCategory = {
        ...this.logictsCategory,
        id,
        label
      }
      saObj.clickLogisticsSelect({ selected_storetype: !Number(id) ? 'all' : id })
      this.toggleShowLogictsCategory()
    },
    toggleShowLogictsCategory() {
      this.logictsCategory.isShow = !this.logictsCategory.isShow
    },
    // Germany dhl
    toShowPostNumber({ item }) {
      if (item.storeType === '19' && !this.storeRegisterCode) {
        this.toggleShowPostNumber()
      }
      const postnumber = item.storeType !== '19' ? 2 : this.storeRegisterCode ? 1 : 0
      saObj.clickSelectStore({
        postnumber,
        store_type: item.storeType
      })
    },
    checkStoreTypeCode() {
      const isPass = this.AddressCheckRule
        ? this.AddressCheckRule.store_type_code({
          value: this.postNumber.code,
          instance: this
        })
        : true
      return isPass
    },
    confirmPostNumber() {
      const isPass = this.checkStoreTypeCode()
      if (isPass) {
        this.toggleShowPostNumber({ type: false })
        this.storeRegisterCode = this.postNumber.code
      }
      saObj.clickSavePostNumber({ result: isPass ? 0 : 1 })
    },
    toggleShowPostNumber({ type = true, from = '' } = {}) {
      const { isShow } = this.postNumber
      if (!isShow) {
        this.error.store_type_code = {
          value: '',
          show: false
        }
        this.postNumber.code = this.storeRegisterCode
      }
      if (from === 'edit') {
        saObj.clickPostnumberConfirm({ postnumber: this.postNumber.code ? 1 : 0 })
      }
      this.postNumber.isShow = type
    },
    getLocationFields({ info = {}, type = 'logo' }) {
      if (type === 'logo') {
        const { storeType, location_type } = info
        if (!this.isShowLogo || !storeType) {
          return ''
        }
        const storeItemLogo =  this.addressStoreConfig?.store_info_list.find(item => item.store_channel_type == storeType)

        const customizeLogo =  storeItemLogo?.store_detail_list?.find(i=>i.location_type == location_type)
        if(customizeLogo?.display_logo) {
          return customizeLogo.display_logo
        }

        return storeItemLogo?.logo ? storeItemLogo?.logo :  RU_STORE_LAST_LOGISTICS[storeType] || ''
      }
      const { location_type, storeType } = info
      if (!this.isShowType || !location_type) {
        return ''
      }
      const storeItemText = this.addressStoreConfig?.store_info_list.find(item => item.store_channel_type == storeType)
      const customizeText = storeItemText?.store_detail_list?.find(i=>i.location_type == location_type)
      
      if (customizeText?.location_type && customizeText?.store_location_type_multi) {
        return customizeText?.store_location_type_multi
      }
      const handle = RU_STORE_LOCATION_TYPE[location_type]?.value || ''
      
      return this.language[handle]
    },
    toShowPicture(link) {
      this.storePicture = {
        isShow: true,
        link
      }
      saObj.clickLockerPicture()
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    async fetchData() {
      const data = await schttp({
        url: '/api/user/addressbook/language/get',
        params: {
          type: 2
        }
      })
      return (data && data.language) || {}
    },
    selectItem(val) {
      this.selectedIndex = val
      saObj.clickListSelectpickupaddress({
        sort: this.sortType,
        store_select: this.getSortPosition({ index: this.selectedIndex })
      })
      this.resultList.forEach((item, index) => {
        this.$set(item, 'childShow', val == index)
        if (val == index) {
          this.toShowPostNumber({ item })
        }
      })
    },
    async initData(data = {}) {
      const {
        show = true,
        postcode = '',
        storeId = '',
        pageName = '',
        countryId = '',
        state = '',
        city = '',
        extraData = {},
        storeRegisterCode = '',
        transport_type,
      } = data
      this.countryId = +countryId
      this.pageName = pageName
      this.storeId = storeId
      this.show = show
      this.transportType = transport_type
      this.searchValue = postcode
      this.resultList = []
      this.errorFlag = false
      this.mapFlag = false
      this.emptyFlag = false
      this.chooseAddressDrawer = false
      this.choseeAddressInfo = {}
      this.extraData = extraData
      this.storeRegisterCode = storeRegisterCode
      this.state = state
      this.city = city
      this.removeMarkers()
    },
    async googleMapInit(data = {}) {
      const { lat = '', lng = '' } = data
      if (!this.mapObj) {
        await asyncLoadFile({
          label: 'script',
          attrs: {
            src: `//maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`,
            async: 'async'
          }
        })
      }
      try {
        this.mapFlag = true
        if (this.resultList.length || (lat && lng)) {
          this.initMap({ lat, lng })
        } else {
          this.defaultMap()
        }
        if (this.resultList.length) {
          !this.selectedIndex && (this.selectedIndex = 0)
          this.chooseAddressDrawer = true
          this.choseeAddressInfo = this.resultList[this.selectedIndex]
          await this.sleep(600)
          this.selectedDefaultMarker(this.markerList[this.selectedIndex])
        }
      } catch (e) {
        console.log(e)
      }
    },
    defaultMap(center = countryConfigs[this.countryId].latLng, zoom = 6) {
      // The center location of Paris
      if (this.mapObj) {
        this.removeMarkers()
        this.mapObj.setCenter(center)
        this.mapObj.setZoom(zoom)
      } else {
        // eslint-disable-next-line no-undef
        this.mapObj = new google.maps.Map(document.getElementById('j-store-map'), {
          zoom,
          center,
          clickableIcons: false,
          gestureHandling: 'greedy'
        })
      }
    },
    initMap(data = {}) {
      const { lat = '', lng = '', zoom = 13 } = data
      const defaultIndex = this.selectedIndex || 0
      const center = {
        lat: parseFloat(lat || this.resultList[defaultIndex].Latitude),
        lng: parseFloat(lng || this.resultList[defaultIndex].Longitude)
      }
      this.defaultMap(center, zoom)
      this.removeMarkers()
      if (lat && lng) {
        // eslint-disable-next-line no-undef
        this.myMarker = new google.maps.Marker({
          position: center,
          icon: icon3,
          map: this.mapObj
        })
      }
      this.resultList.map((item, index) => {
        this.addMarker(item, index)
      })
      this.mapObj.addListener('dragstart', () => {
        this.chooseAddressDrawer = false
      })
    },
    addMarker(item, index) {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        resultIndex: index,
        resultItem: item,
        position: {
          lat: parseFloat(item.Latitude),
          lng: parseFloat(item.Longitude)
        },
        icon: this.selectedIndex === index ? icon2 : icon1,
        map: this.mapObj
      })
      if (item.distance) {
        // eslint-disable-next-line no-undef
        const infowindow = new google.maps.InfoWindow({
          content: `<div class="${['popup-info', item.isRecommend ? 'recommend' : '']
            .filter(Boolean)
            .join(' ')}">
            <div class="distance">
              <i class="suiiconfont sui_icon_shop_16px_2"></i>
              <span class="num">${this.transformDistance(item)}</span>
            </div>
            <div class="triangle"></div>
          </div>`
        })
        infowindow.open({
          anchor: marker,
          map: this.mapObj,
          shouldFocus: false
        })
      }
      if (this.selectedIndex === index) {
        this.selectedMarker = marker
      }
      marker.addListener('click', () => {
        this.selectedDefaultMarker(marker)
        this.toShowPostNumber({ item: marker.resultItem })
      })
      this.markerList.push(marker)
      return marker
    },
    selectedDefaultMarker(marker) {
      this.selectedIndex = marker.resultIndex
      this.chooseAddressDrawer = true
      this.choseeAddressInfo = marker.resultItem
      if (this.selectedMarker) {
        this.selectedMarker.setIcon(icon1)
      }
      marker.setIcon(icon2)
      this.mapObj.setCenter({
        lat: parseFloat(marker.resultItem.Latitude),
        lng: parseFloat(marker.resultItem.Longitude)
      })
      this.mapObj.setZoom(16)
      this.selectedMarker = marker
    },
    removeMarkers() {
      this.markerList.map(item => {
        item.setMap(null)
      })
      this.markerList = []
      this.myMarker && this.myMarker.setMap(null)
    },
    async nearestForMe({
      state = '',
      city = '',
      show = true,
      lat = '',
      lng = '',
      storeId = '',
      pageName = '',
      countryId = '',
      extraData = {},
      storeRegisterCode = ''
    }) {
      this.state = city
      this.city = state
      this.countryId = +countryId
      this.pageName = pageName
      this.storeId = storeId
      this.extraData = extraData
      this.storeRegisterCode = storeRegisterCode
      this.show = show
      if (lat && lng) {
        await this.getMrInfo({ lat, lng, state, city })
      }
      this.mapFlag = true
      this.googleMapInit({ lat, lng })
    },
    setMapMarker() {
      if (this.resultList.length && this.mapFlag) {
        this.initMap()
      } else {
        this.mapFlag = false
      }
      this.resultList.map((item, index) => {
        if (item.storeId == this.storeId) {
          this.selectedIndex = index
        }
      })
    },
    async clickSearch(data = {}) {
      const { lat = '', lng = '', address1 = '', address2 = '' } = data
      if (lat && lng) {
        const { city, postcode, countryCode } = data
        const isFr = countryCode ? this.isFr && countryCode === 'FR' : this.isFr
        const postData = isFr
          ? { latitude: lat, longitude: lng, state: '', city, postcode, address1, address2 }
          : { latitude: lat, address1, address2, longitude: lng }
        await this.getStoreLists(postData)
        this.setMapMarker()
      } else {
        const flag = this.AddressCheckRule
          ? this.AddressCheckRule?.postcode({ value: this.searchValue, instance: this })
          : true
        if (flag) {
          this.errorFlag = false
          let latLng = { lat: '', lng: '' }
          const canGeocode =
            SHOP_TRANSIT_GEOCODE_COUNTRIES?.includes(+this.countryId) && this.isSearchHome
          const canGetGeo = canGeocode && !!(this.searchValue || this.state || this.city)
          if (canGetGeo) {
            const form = {
              postal_code: this.searchValue,
              administrative_area: this.state,
              locality: this.city,
              country: GOOGLE_DATA?.[this.countryId]?.country
            }
            latLng = await this.getGeo(form)
          }
          const { lat, lng } = latLng
          await this.getStoreLists({
            latitude: lat,
            longitude: lng,
            postcode: this.searchValue,
            state: this.state,
            city: this.city,
            address1,
            address2
          })
          this.setMapMarker()
        } else {
          this.errorFlag = true
          this.resultList = []
          this.emptyFlag = false
        }
      }
    },
    formatOpenTimeList(lists) {
      const {
        SHEIN_KEY_PWA_16638,
        SHEIN_KEY_PWA_16639,
        SHEIN_KEY_PWA_16640,
        SHEIN_KEY_PWA_16641,
        SHEIN_KEY_PWA_16642,
        SHEIN_KEY_PWA_16643,
        SHEIN_KEY_PWA_16644
      } = this.language
      const date = [
        SHEIN_KEY_PWA_16638,
        SHEIN_KEY_PWA_16639,
        SHEIN_KEY_PWA_16640,
        SHEIN_KEY_PWA_16641,
        SHEIN_KEY_PWA_16642,
        SHEIN_KEY_PWA_16643,
        SHEIN_KEY_PWA_16644
      ]
      return lists.map(item => {
        const { day, open_close_time_list = [] } = item
        const time = open_close_time_list.map(({ open_time, close_time }) => {
          return `${[open_time, close_time].filter(Boolean).join('-')}`
        })
        return {
          date: date[day - 1],
          time: time.filter(Boolean).join(' ')
        }
      })
    },
    resolveDistanceConfig(distance) {
      if (this.abtTestConfig.distance !== 'on' || distance === '') {
        return {
          distance: '',
          isRecommend: false
        }
      }
      if (this.sortType !== 1) {
        return {
          distance,
          isRecommend: false
        }
      }
      const curDistance = Number(distance || 0)
      const maxDistance = Number(this.addressStoreConfig?.store_config?.distance || 0)
      const isRecommend = curDistance < maxDistance
      return {
        distance: String(isRecommend ? maxDistance : curDistance),
        isRecommend
      }
    },
    setAddressLists(addressList) {
      const {
        SHEIN_KEY_PWA_16638,
        SHEIN_KEY_PWA_16639,
        SHEIN_KEY_PWA_16640,
        SHEIN_KEY_PWA_16641,
        SHEIN_KEY_PWA_16642,
        SHEIN_KEY_PWA_16643,
        SHEIN_KEY_PWA_16644
      } = this.language
      const lists = addressList.map(item => {
        const { distance, isRecommend } = this.resolveDistanceConfig(
          item.Distance || item.distance || ''
        )
        // 新加坡展示不同数据
        const addressList = this.countryId == 191 ? [ 'address2', 'address1', 'district', 'city', 'state', 'postcode'] : ['address1', 'address2', 'street', 'district']
        return {
          storeName: item.store_name,
          address: addressList.map(type => item[type]).filter(Boolean).join(' '),
          postcode: item.postcode,
          storeId: item.store_id,
          storeType: item.type,
          city: item.city,
          Latitude: item.lat,
          Longitude: item.lng,
          distance,
          isRecommend,
          store247: item.store247 || '',
          keyword: item.keyword,
          keywordId: item.keyword_id,
          image: item.image || '',
          last_logistics: item.last_logistics || '',
          location_type: item.location_type || '',
          open_close_time: item.open_close_time || '',
          timeLists: isArray(item.open_time_list)
            ? this.formatOpenTimeList(item.open_time_list)
            : [
              {
                date: SHEIN_KEY_PWA_16638,
                time: `${item.mon_open_time}-${item.mon_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16639,
                time: `${item.tue_open_time}-${item.tue_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16640,
                time: `${item.wed_open_time}-${item.wed_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16641,
                time: `${item.thu_open_time}-${item.thu_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16642,
                time: `${item.fri_open_time}-${item.fri_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16643,
                time: `${item.sat_open_time}-${item.sat_close_time}`
              },
              {
                date: SHEIN_KEY_PWA_16644,
                time: `${item.sun_open_time}-${item.sun_close_time}`
              }
            ]
        }
      })
      return lists
    },
    async getStoreLists({ postcode, latitude, longitude, state, city, address1, address2 }) {
      await this.getMrInfo({
        postcode,
        lat: latitude,
        lng: longitude,
        state,
        city,
        address1,
        address2
      })
    },
    async getGeo(form) {
      try {
        const data = await schttp({
          url: '/api/user/addressbook/geo/get',
          params: form
        })
        const { lat: lt = '', lng: lg = '' } = data?.results?.[0]?.geometry?.location || {}
        return { lat: lt, lng: lg }
      } catch (error) {
        return {}
      }
    },
    async getMrInfo(data) {
      this.logictsCategory.id = '0'
      this.logictsCategory.label = ''
      const {
        postcode = '',
        lat = '',
        lng = '',
        state = '',
        city = '',
        address1 = '',
        address2 = ''
      } = data
      const { mallList = [] } = this.extraData || {}
      const transportType = mallList?.[0]?.transportType
      const cacheKey = `COUNTRY_${this.countryId}_POSTCODE_${postcode}_TRANSPORT_${transportType}`
      if (postcode && this.searchCache[cacheKey]) {
        this.selectedIndex = ''
        this.chooseAddressDrawer = false
        this.resultList = this.searchCache[cacheKey]
        saObj.exposeStoreSuggestion({
          store_sort: this.resultList.some(
            item => Number(item.distance) < Number(this.resultList?.[0]?.distance)
          )
            ? 0
            : 1
        })
        this.emptyFlag = false
        return
      }
      const {
        shippingDate = '',
        goodsTotalWeight = '',
        pageSource = '',
      } = this.extraData || {}
      const accuracy = GOOGLE_LAT_LNG_ACCURACY?.[this.countryId] || 4
      const pageSources = {
        order_return: 0,
        checkout: 1
      }
      let form = {
        countryId: this.countryId,
        postcode,
        latitude: lat ? Number(lat)?.toFixed(accuracy) : '',
        longitude: lng ? Number(lng)?.toFixed(accuracy) : '',
        state: this.isFr ? state : '',
        city: this.isFr ? city : '',
        mallList,
        shippingDate,
        typeList: this.channels,
        goodsTotalWeight,
        sortType: this.sortType,
        pageSource: Object.keys(pageSources).includes(pageSource) ? pageSources?.[pageSource] : 2,
        transportCode: transportType
      }
      if (this.isFrOrDe && address1) {
        form.address1 = address1
      }
      if (this.isFrOrDe && address2) {
        form.address2 = address2
      }
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/user/addressbook/storeAddressList/get',
          data: form
        })
        const addressList = res.code == 0 && res.info && (res.info.addressList || res.info.result)
        if (addressList.length) {
          this.resultList = this.setAddressLists(addressList)
          this.originResultList = JSON.parse(JSON.stringify(this.resultList))
          saObj.exposeStoreSuggestion({
            store_sort: this.resultList.some(
              item => Number(item.distance) < Number(this.resultList?.[0]?.distance)
            )
              ? 0
              : 1
          })
          this.selectedIndex = ''
          this.chooseAddressDrawer = false
          this.searchCache[cacheKey] = this.resultList
          this.emptyFlag = false
        } else {
          this.resultList = []
          this.emptyFlag = true
        }
      } catch (error) {
        this.resultList = []
        this.originResultList = []
        this.emptyFlag = true
      }
    },
    clickCancel() {
      if (this.mapFlag) {
        this.mapFlag = false
        this.chooseAddressDrawer = false
        setTimeout(() => {
          this._scrollTo(this.selectedIndex)
        }, 300)
      } else {
        // if(this.$refs.searchListRef){
        //    this.$refs.searchListRef.clear()
        //   }
        // this.searchValue = ''
        this.show = false
        this.logictsCategory.id = '0'
        this.logictsCategory.label = ''
      }
    },
    clickSearchClose() {
      this.searchValue = ''
    },
    clickChildStatus(item) {
      this.$set(item, 'childShow', !item.childShow)
    },
    getSortPosition({ index } = {}) {
      if (this.sortType === 0) {
        return 0
      }
      const { isRecommend } = this.resultList[index] || {}
      const position = isRecommend ? (index == 0 ? 1 : 2) : 3
      return position
    },
    clickSelected() {
      this.chooseAddressDrawer = false
      this.$nextTick(() => {
        this.show = false
        const item = this.resultList[this.selectedIndex] || {}
        saObj.clickStoreConfirm({ store_type: item.storeType })
        saObj.clickSelectpickupaddressMapConfirm({
          sort: this.sortType,
          store_select: this.getSortPosition({ index: this.selectedIndex })
        })
        this.$emit('select', {
          ...item,
          storeRegisterCode: this.storeRegisterCode
        })
      })
    },
    searchInputFocus() {
      this.searchFocus = true
      this.logictsCategory.isShow = false
      this.$refs['searchInput'].focus()
    },
    searchInputBlur() {
      if (!this.searchValue) {
        this.searchFocus = false
      }
    },
    clickSelectMap() {
      this.googleMapInit()
    },
    clickDrawerClose() {
      this.chooseAddressDrawer = false
    },
    _scrollTo(index) {
      const letterItems = this.$refs['j-item-' + index]
      if (letterItems && letterItems.length) {
        const letterItem = letterItems[0]
        this.$refs.resultList.scrollTop = letterItem.offsetTop - this.$refs.upperHeader.clientHeight
      }
    },
    transformDistance(item = {}) {
      const { distance, isRecommend } = item
      if (!distance) {
        return ''
      }
      if (this.countryId == 225) {
        const miles = (distance * 0.00062137119223733).toFixed(2)
        return `${item.isRecommend ? '< ' : ''}${miles < 0.01 ? 0.01 : miles}miles`
      }
      if (distance.length > 3) {
        const [int, dec] = (distance / 1000).toFixed(2).split('.')
        return `${isRecommend ? '< ' : ''}${Number(int).toLocaleString()}.${dec}km`
      }
      return `${isRecommend ? '< ' : ''}${distance}m`
    },
    handleSearchlistFocus() {
      this.logictsCategory.isShow = false
      this.chooseAddressDrawer = false
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.choose-address-page {
  height: auto;
  .choose-address-wrap {
    position: relative;
    .header-close {
      position: absolute;
      .right(0.21333rem);
      top: 0.21333rem;
      z-index: 9999; /* stylelint-disable-line declaration-property-value-blacklist */
    }
    .content {
      color: #666;
      .font-dpr(28px);
      max-height: 6rem;
      overflow-y: auto;
      padding: 0 0.29333rem 1.6rem;
      box-sizing: content-box;
      h5 {
        .font-dpr(28px);
        color: #222;
        margin: 0.86333rem 0 0.26666rem;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1;
      }
      .desc {
        margin-top: 0.213rem;
        color: #666;
        line-height: 1;
      }
      .open-time {
        margin-top: 0.213rem;
      }
      .child {
        .time-list {
          > li {
            .flexbox;
            .date {
              flex: 0 0 3rem;
            }
            .time {
              flex: 1;
            }
          }
        }
      }
      .logistics {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 0.319rem;
        .logo-panel {
          display: inline-flex;
          align-items: center;
          .margin-r(0.213rem);
        }
        .logo {
          width: 0.48rem;
          height: auto;
        }
        .type {
          padding: 0.053rem 0.106rem;
          color: #666;
          .font-dpr(24px);
          word-break: break-word;
          background: #eff3f8;
        }
        .recommend {
          display: flex;
          align-items: center;
          .margin-r(0.213rem);
          padding: 0.1rem 0.126rem;
          background: @sui_color_safety_bg;
          color: @sui_color_safety;
          .font-dpr(24px);
          line-height: 1;
          font-weight: bold;
          .txt {
            .margin-l(0.126rem);
          }
        }
      }
      .flex {
        display: flex;
        align-items: center;
        .suiiconfont {
          align-self: flex-start;
          color: #222;
          color: #222;
          .font-dpr(30px);
        }
        .info {
          flex: 1;
          color: @sui_color_gray_dark2;

          .margin-l(0.16rem);
          &.inline {
            display: inline-flex;
            flex-direction: column;
            .link {
              color: @sui_color_link;
              .font-dpr(28px);
              text-decoration: none;
            }
          }
        }
        &.title {
          justify-content: space-between;
        }
      }
      .post-number {
        padding: 0.213rem 0.32rem;
        margin-top: 0.267rem;
        background: @sui_color_gray_weak2;
        .info {
          margin: 0;
          .margin-r(0.16rem);
          color: @sui_color_gray_dark3;
          font-size: 12px;
        }
      }
    }
    .bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.21333rem 0.32rem;
      background: #fff;
    }
  }
}
.j-map-address {
  .c-address-content {
    &.page-empty {
      background: #fff;
    }
    .address {
      .header-search {
        position: relative;
        .flexbox;
        .align-center;
        padding: 0.16rem 0.32rem;
        background: #fff;
        .search-input {
          position: relative;
          padding: 0;
          flex: 1;
          > input {
            width: 100%;
            background: #f6f6f6;
            height: 0.85333rem;
            line-height: 0.85333rem;
            .font-dpr(28px);
            border: none;
            padding: 0 0.90666rem;
          }
          .icon-search01 {
            position: absolute;
            .left(0.21333rem);
            top: 0.1rem;
            .font-dpr(36px);
            color: #999;
          }
          .icon-clear1 {
            position: absolute;
            .right(0.21333rem);
            top: 0.18rem;
            color: #ccc;
            .font-dpr(28px);
          }
          .text-placeholer {
            position: absolute;
            top: 0.2rem;
            left: 0.9rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 6rem;
            color: #ccc;
          }
        }
        .search-right {
          .font-dpr(28px);
          color: #222;
          .padding-l(0.32rem);
        }
      }
      .search-error {
        padding: 0.053333rem 0.32rem 0.32rem;
        .font-dpr(28px);
        color: #ff411c;
        background: #fff;
      }
      .select-map {
        padding: 0.21333rem 0;
        text-align: center;
        background: #fff;
        color: #093c6e;
        .border-dpr(border-top, 2px, #e5e5e5);
        .border-dpr(border-bottom, 2px, #e5e5e5);
        .suiiconfont {
          .font-dpr(30px);
        }
        .value {
          .font-dpr(28px);
          padding: 0 0.05rem;
        }
      }
      .search-result {
        background: #fff;
        .result-list {
          .margin-l(0.32rem);
          margin-bottom: 1.5rem;
          .result-item {
            .flexbox;
            align-items: flex-start;
            .border-dpr(border-bottom, 2px, #e5e5e5);
            padding: 0.32rem;
            .padding-l(0);
            .result-content {
              flex: 1;
              .padding-l(0.26666rem);
              color: #666;
              .font-dpr(28px);
              .title {
                .font-dpr(32px);
                color: #222;
                width: 8.5rem;
                margin-bottom: 0.08rem;
                .distance {
                  .margin-l(0.267rem);
                  .font-dpr(24px);
                  white-space: nowrap;
                }
              }
              .desc {
                .font-dpr(28px);
                color: #666;
                margin-bottom: 0.21333rem;
              }
              .child {
                .padding-l(0.57rem);
                .time-list {
                  > li {
                    .flexbox;
                    padding: 0.08rem 0;
                    .date,
                    .time {
                      flex: 1;
                    }
                  }
                }
              }
              .flex {
                display: flex;
                align-items: center;
                .suiiconfont {
                  align-self: flex-start;
                  color: #222;
                  color: #222;
                  .font-dpr(30px);
                }
                .info {
                  flex: 1;
                  color: @sui_color_gray_dark2;
                  .font-dpr(28px);
                  .margin-l(0.16rem);
                  &.inline {
                    display: inline-flex;
                    flex-direction: column;
                    .link {
                      color: @sui_color_link;
                      .font-dpr(28px);
                      text-decoration: none;
                    }
                  }
                }
                &.title {
                  justify-content: space-between;
                }
              }
              .post-number {
                padding: 0.213rem 0.32rem;
                margin-top: 0.267rem;
                background: @sui_color_gray_weak2;
                .info {
                  margin: 0;
                  .margin-r(0.16rem);
                  color: @sui_color_gray_dark3;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .map {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 2.4rem;
      &.error {
        top: 3.3rem;
      }
      .gm-style-mtc,
      .gm-svpc,
      .gm-fullscreen-control {
        display: none;
      }
    }
    .page-empty {
      text-align: center;
      .font-dpr(28px);
      color: #999;
      > img {
        width: 2rem;
        margin-top: 2rem;
      }
      > p {
        padding: 0.64rem;
      }
    }
    &.c-map-wrap.ru {
      .search-btn {
        position: fixed;
        top: 1.84rem;
        .right(0.64rem);
        z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.33rem;
        height: 1.33rem;
        background: #fff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
        border-radius: 0.106rem;
        .sui_icon_nav_search_18px {
          color: #222;
          .font-dpr(56px);
        }
      }
      .map {
        top: 1.17rem;
      }
      .choose-address-page .choose-address-wrap .content {
        max-height: 6.5rem;
      }
    }
  }
  .result-bottom {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    padding: 0.21333rem 0.32rem;
  }
}
.j-address-box {
  .c-address-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 200; /* stylelint-disable-line declaration-property-value-blacklist */
  }
}
.c-google-map-modal {
  .gm-style-iw.gm-style-iw-c {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    border-radius: 0;
    background: transparent !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    .popup-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      margin-bottom: -5px;
      .distance {
        padding: 12px;
        background: #fff;
        box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.3);
      }
      .triangle {
        border-top: 12px solid #fff;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
      }
      &.recommend {
        .distance {
          background: @sui_color_safety_bg;
          color: @sui_color_safety;
          font-weight: bold;
        }
        .triangle {
          border-top: 12px solid @sui_color_safety_bg;
        }
      }
    }
    button.gm-ui-hover-effect {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
    .gm-style-iw-d {
      overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .gm-style-iw-t {
    .gm-style-iw-tc {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .search__top {
    position: fixed;
    top: 1.17rem;
    left: 0;
    z-index: @zindex-transform-review;
    width: 100%;
    .filter {
      position: relative;
      &__category {
        padding: 0.32rem;
        cursor: pointer;
        background: @sui_color_white;
        .text {
          font-weight: 700;
        }
      }
      &__select {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: @zindex-out;
        width: 100%;
      }
      &__list {
        padding: 0 16px;
        background: @sui_color_white;
      }
      .modal {
        position: absolute;
        height: 1000%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 0.32rem 0;
        cursor: pointer;
        .label {
          font-size: 14px;
        }
        &.selected {
          font-weight: 700;
        }
        .suiiconfont {
          font-weight: normal;
        }
      }
    }
  }
  .search-result {
    margin-top: 2.3rem;
  }
}
.store-picture {
  .close {
    position: absolute;
    top: 0.43rem;
    left: 0.53rem;
    color: #fff;
    font-weight: 100;
    font-size: 0.4rem;
    -webkit-text-stroke: 0.02rem #fff;
  }
}
.c-address-content {
  .result-content {
    .logistics {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      margin-top: 0.319rem;
      .logo-panel {
        display: inline-flex;
        align-items: center;
        .margin-r(0.213rem);
      }
      .logo {
        width: 0.48rem;
        height: auto;
      }
      .type {
        padding: 0.053rem 0.106rem;
        .margin-r(0.213rem);
        color: #666;
        .font-dpr(24px);
        word-break: break-word;
        background: #eff3f8;
      }
      .recommend {
        display: flex;
        align-items: center;
        .margin-r(0.213rem);
        padding: 0.1rem 0.126rem;
        background: @sui_color_safety_bg;
        color: @sui_color_safety;
        .font-dpr(24px);
        line-height: 1;
        font-weight: bold;
        .txt {
          .margin-l(0.126rem);
        }
      }
    }
  }
}
.store-post-number {
  .content {
    padding: 0.32rem;
  }
  .desc {
    margin-bottom: 0.427rem;
    color: @sui_color_gray_dark1;
    text-align: left;
  }
  .input {
    padding: 0.32rem;
    width: 100%;
    outline: 0;
    border: none;
    background: @sui_color_gray_weak2;
    &::-webkit-input-placeholder {
      color: @sui_color_gray_light2;
    }
    &:-moz-placeholder {
      color: @sui_color_gray_light2;
    }
    &::-moz-placeholder {
      color: @sui_color_gray_light2;
    }
    &:-ms-input-placeholder {
      color: @sui_color_gray_light2;
    }
  }
  .btn {
    margin-top: 0.32rem;
  }
  .error {
    margin-top: 0.107rem;
    color: @sui_color_unusual;
    font-size: 12px;
    text-align: left;
  }
}

/* shipping抽屉 */
.mche-ship-drawer-aside {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: #fff;
  height: 8.19rem;
  .shipping-drawer-header {
    height: 1.07rem;
    background: #fff;
    width: 100%;
    a {
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      text-decoration: none;
      .icon-close {
        font-size: 16px;
      }
    }
    .drawer-header-title {
      text-align: center;
      color: #999999;
      position: absolute;
      left: 0;
      right: 0;
      font-weight: 500;
      .font-dpr(32px);
    }
  }
  .shipping-drawer-scroll-box {
    padding-bottom: 1.96rem + 0.32rem + 0.21rem;
    width: 100%;
    overflow-y: scroll;
  }
}
</style>
